import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { reformatDate } from 'utils';
import { currentClientYearChecklist } from '_shared/redux/checklist/selectors';
import { AccountingBalances } from 'types/Accounting';
import {
  fetchChecklistStatuses,
  getChecklistForProgram,
} from '_shared/redux/checklist/actions';
import { ALL_PROGRAMS } from '_shared/redux/checklist/constants';

import ChecklistProgram from './ChecklistProgram';
import TaxesDataContext from '../../../service/TaxesDataContext';

type ChecklistsProps = {
  accountingBalances: AccountingBalances;
  isGroup?: boolean;
};

const programsToLoad = ALL_PROGRAMS.filter(
  (item) => item !== 'tax_declaration_person'
);

const Section = styled.section`
  @media print {
    page-break-inside: avoid;
    page-break-after: always;
    max-width: 1280px;
    margin: 0;
    border: none;
    border-radius: 0;
  }
`;

const Checklists = ({
  accountingBalances,
  isGroup = false,
}: ChecklistsProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { clientId, financialYear, period } = useContext(TaxesDataContext);

  const { programs = {}, statuses = {} } =
    useSelector(currentClientYearChecklist(clientId, financialYear)) || {};

  const accountingPeriod = accountingBalances.periods.find(
    (p) => p.start === reformatDate(period, 'yyyyMMdd', 'yyyy-MM-dd')
  );

  const fetchChecklistData = useCallback(async () => {
    const accountsList = accountingBalances.accounts || [];

    programsToLoad.forEach((program) => {
      if (!programs[program]) {
        dispatch(
          getChecklistForProgram(clientId, financialYear, program, accountsList)
        );
      }
    });
  }, [
    accountingBalances.accounts,
    programs,
    dispatch,
    clientId,
    financialYear,
  ]);

  const fetchStatuses = useCallback(async () => {
    dispatch(fetchChecklistStatuses(clientId, financialYear));
  }, [dispatch, clientId, financialYear]);

  useEffect(() => {
    fetchChecklistData();
  }, [fetchChecklistData]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  if (!accountingPeriod) {
    return null;
  }

  return (
    <>
      {Object.keys(programs).map((program) => (
        <Section
          id={`${isGroup ? 'group' : 'account'}checklist${
            programs[program].id
          }`}
          key={program}
        >
          <ChecklistProgram
            checklist={programs[program]}
            statuses={statuses}
            period={accountingPeriod}
          />
        </Section>
      ))}
    </>
  );
};

export default Checklists;
